import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1028.000000 1025.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1025.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M5544 6670 c-73 -47 -45 -169 41 -186 47 -9 101 18 121 59 21 43 13
80 -26 118 -35 35 -89 39 -136 9z"
          />
          <path
            d="M5498 5693 c-3 -451 -7 -618 -16 -645 -22 -64 -73 -120 -141 -155
-54 -29 -73 -33 -137 -33 -167 0 -270 81 -291 230 l-8 55 -94 3 c-52 1 -98 -1
-102 -5 -4 -4 -4 -40 1 -79 25 -195 155 -321 372 -360 249 -45 484 54 572 241
38 81 46 144 46 365 l0 210 144 0 144 0 75 -137 c81 -149 139 -258 182 -340
l27 -53 89 0 c49 0 89 3 89 8 -1 8 -38 77 -188 349 -62 111 -112 205 -112 208
0 2 22 16 48 29 68 35 125 94 162 170 29 59 33 76 33 149 0 145 -43 241 -140
311 -96 70 -191 86 -515 86 l-237 0 -3 -607z m599 439 c88 -46 127 -113 127
-222 1 -64 -3 -82 -26 -120 -63 -107 -123 -130 -343 -130 l-155 0 0 251 0 251
178 -4 c158 -4 181 -6 219 -26z"
          />
          <path
            d="M6480 5620 l0 -640 405 0 405 0 0 70 0 70 -322 0 -323 0 0 225 0 225
278 0 277 0 0 70 0 70 -276 2 -276 3 -1 202 -2 203 318 0 317 0 0 70 0 70
-400 0 -400 0 0 -640z"
          />
          <path
            d="M7339 6238 c0 -2 -1 -286 -2 -631 l-2 -628 75 3 75 3 0 290 0 290
329 0 328 0 -1 -287 c-2 -327 -10 -302 91 -296 35 2 55 8 60 18 3 8 7 291 7
628 l1 612 -64 0 c-104 0 -95 24 -95 -271 0 -196 3 -259 12 -259 7 0 4 -5 -5
-10 -10 -6 -141 -9 -339 -8 l-322 3 1 250 c1 138 -1 260 -4 273 -6 20 -12 22
-75 22 -38 0 -69 -1 -70 -2z"
          />
          <path
            d="M7631 4714 c-12 -15 -21 -30 -21 -33 0 -13 32 -51 51 -62 32 -17 69
13 69 56 0 64 -61 88 -99 39z"
          />
          <path
            d="M4387 4450 c3 -11 7 -20 9 -20 2 0 4 9 4 20 0 11 -4 20 -9 20 -5 0
-7 -9 -4 -20z"
          />
          <path
            d="M4521 4346 c-11 -13 -11 -17 3 -22 26 -10 38 -1 23 20 -12 16 -14 16
-26 2z"
          />
          <path
            d="M7150 4301 c-13 -25 -13 -27 6 -45 23 -23 51 -19 70 9 13 20 14 28 4
40 -18 22 -67 19 -80 -4z"
          />
          <path
            d="M7474 4310 c-29 -11 -54 -48 -54 -78 0 -13 9 -37 21 -53 29 -40 79
-40 120 0 70 70 4 168 -87 131z"
          />
          <path
            d="M4717 4267 c-2 -7 -2 -18 0 -25 4 -10 8 -10 19 -1 8 6 14 15 14 19 0
14 -28 19 -33 7z"
          />
          <path d="M4916 4204 c-4 -9 -4 -19 -2 -21 8 -8 26 8 26 23 0 19 -16 18 -24 -2z" />
          <path
            d="M4320 4203 c0 -13 29 -38 35 -31 3 3 -4 13 -15 23 -11 10 -20 13 -20
8z"
          />
          <path
            d="M5100 4170 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
          />
          <path d="M4248 4133 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path
            d="M6607 4103 c-14 -14 -7 -43 11 -43 24 0 42 19 35 36 -5 15 -35 19
-46 7z"
          />
          <path
            d="M6030 4086 c0 -2 7 -9 15 -16 12 -10 15 -10 15 4 0 9 -7 16 -15 16
-8 0 -15 -2 -15 -4z"
          />
          <path d="M4518 4063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path
            d="M4477 4023 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
          />
          <path d="M4531 3996 c-9 -11 -8 -15 5 -20 20 -8 34 1 34 20 0 18 -24 18 -39 0z" />
          <path
            d="M6896 3988 c-21 -30 -20 -50 5 -82 35 -45 109 -29 109 23 0 67 -80
108 -114 59z"
          />
          <path
            d="M4740 3970 c-8 -15 -8 -30 0 -55 13 -38 25 -42 54 -20 16 12 17 17 6
30 -11 13 -10 14 6 9 14 -5 11 1 -10 24 -35 38 -42 39 -56 12z"
          />
          <path
            d="M5792 3968 c2 -15 10 -23 23 -23 30 0 27 39 -3 43 -20 3 -23 0 -20
-20z"
          />
          <path
            d="M7221 3932 c-59 -18 -88 -125 -47 -171 48 -53 142 -48 171 10 23 44
19 111 -7 136 -26 24 -81 35 -117 25z"
          />
          <path
            d="M4991 3916 c-8 -10 -6 -19 9 -36 11 -13 20 -29 20 -37 0 -9 8 -13 23
-11 18 2 23 10 25 41 2 27 0 37 -10 35 -7 -1 -23 3 -34 10 -17 9 -24 9 -33 -2z"
          />
          <path
            d="M5530 3915 c-20 -25 -8 -50 25 -50 21 0 31 5 33 18 4 21 -14 47 -33
47 -7 0 -18 -7 -25 -15z"
          />
          <path
            d="M6336 3904 c-22 -22 -20 -50 6 -68 29 -21 48 -20 63 1 10 13 10 23 2
43 -19 43 -44 51 -71 24z"
          />
          <path
            d="M5262 3898 c-19 -19 -14 -38 11 -36 12 1 32 -2 45 -6 24 -8 28 3 12
35 -12 21 -50 25 -68 7z"
          />
          <path d="M4210 3872 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z" />
          <path
            d="M5265 3834 c22 -17 46 -15 53 4 2 8 -8 12 -35 12 -36 -1 -37 -1 -18
-16z"
          />
          <path
            d="M6043 3752 c-33 -26 -38 -63 -13 -90 11 -12 25 -22 31 -22 15 0 53
21 61 34 16 23 8 65 -14 80 -28 20 -38 20 -65 -2z"
          />
          <path
            d="M4386 3741 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z"
          />
          <path
            d="M6595 3715 c-43 -43 -33 -106 22 -132 42 -20 77 -10 106 29 56 75
-62 170 -128 103z"
          />
          <path
            d="M4412 3708 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"
          />
          <path
            d="M4484 3685 c-6 -14 11 -45 25 -45 19 1 32 20 26 39 -7 24 -43 29 -51
6z"
          />
          <path
            d="M4406 3682 c6 -4 13 -15 17 -24 7 -21 37 -24 37 -5 0 8 -11 19 -25
25 -29 13 -46 16 -29 4z"
          />
          <path
            d="M5712 3637 c-37 -39 -27 -92 21 -112 32 -13 72 -4 89 20 15 21 7 63
-17 89 -30 32 -65 33 -93 3z"
          />
          <path
            d="M4734 3640 c-29 -12 -54 -49 -54 -80 0 -24 24 -55 58 -75 18 -10 102
36 102 56 0 9 3 31 6 49 7 32 7 32 -17 19 -13 -8 -33 -14 -44 -14 -13 0 -21
-9 -26 -32 -5 -27 -3 -33 15 -36 11 -3 3 -4 -18 -4 -78 4 -64 87 15 87 42 0
51 13 20 30 -23 12 -29 12 -57 0z"
          />
          <path
            d="M5046 3587 c-23 -17 -32 -95 -14 -118 17 -20 74 -40 96 -32 37 13 52
36 52 79 0 34 -5 47 -26 63 -30 24 -81 27 -108 8z"
          />
          <path
            d="M5413 3581 c-9 -12 -23 -21 -30 -21 -16 0 -32 -31 -25 -50 9 -24 46
-58 68 -62 31 -5 93 47 90 75 -6 60 -70 96 -103 58z"
          />
          <path
            d="M6914 3590 c-11 -4 -35 -22 -52 -40 -37 -36 -43 -90 -17 -140 44 -85
195 -67 225 25 10 31 9 41 -11 81 -32 68 -89 97 -145 74z"
          />
          <path
            d="M6286 3520 c-49 -15 -66 -38 -66 -89 0 -55 5 -63 47 -85 70 -36 130
-6 140 71 4 34 1 45 -27 76 -33 38 -45 41 -94 27z"
          />
          <path d="M3865 3440 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
          <path
            d="M4035 3410 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
          />
          <path
            d="M4320 3400 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
          />
          <path
            d="M4254 3379 c-19 -22 -19 -22 4 -10 12 6 22 16 22 21 0 15 -5 12 -26
-11z"
          />
          <path d="M4351 3357 c-6 -8 -8 -16 -5 -19 6 -7 54 14 54 25 0 12 -37 8 -49 -6z" />
          <path
            d="M5892 3358 c-12 -6 -32 -26 -43 -45 -27 -43 -20 -82 20 -126 56 -59
145 -37 171 43 12 37 12 44 -5 73 -37 63 -86 82 -143 55z"
          />
          <path
            d="M4269 3343 c0 -5 -2 -18 -3 -30 -2 -13 1 -23 6 -23 13 0 48 34 48 47
0 14 -49 19 -51 6z"
          />
          <path
            d="M6510 3293 c-41 -25 -60 -65 -60 -129 0 -41 67 -113 107 -116 56 -3
77 4 120 39 37 31 43 40 43 75 0 41 -27 105 -55 128 -9 8 -39 17 -66 20 -41 5
-56 2 -89 -17z"
          />
          <path
            d="M4334 3304 c-7 -3 -10 -14 -7 -24 3 -11 0 -20 -6 -20 -6 0 -11 -7
-11 -15 0 -21 27 -19 61 6 18 13 36 18 49 14 14 -5 20 -2 20 10 0 8 -6 17 -12
20 -26 8 -83 13 -94 9z"
          />
          <path
            d="M4642 3261 c-46 -41 -61 -83 -41 -107 7 -9 13 -26 14 -37 0 -18 26
-40 74 -63 7 -4 22 -2 32 4 10 7 25 9 34 6 8 -4 26 1 38 9 28 18 57 120 41
145 -16 24 -28 24 -43 0 -10 -16 -10 -23 -2 -26 6 -2 11 -13 10 -25 l0 -22
-16 23 c-15 22 -43 30 -43 13 0 -5 -7 -12 -16 -15 -25 -10 -12 27 14 40 l24
12 -22 20 c-21 19 -21 21 -3 30 23 13 10 26 -27 28 -20 1 -40 -9 -68 -35z m26
-30 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z
m29 -111 l26 -20 -26 0 c-36 0 -57 10 -57 26 0 20 28 17 57 -6z"
          />
          <path d="M4755 3270 c-10 -17 12 -37 25 -24 8 8 6 15 -4 23 -10 9 -16 9 -21 1z" />
          <path
            d="M5500 3272 c-8 -4 -30 -20 -47 -37 -49 -44 -46 -88 10 -148 39 -41
48 -45 81 -42 41 3 91 43 107 82 23 59 -30 140 -96 148 -22 2 -47 1 -55 -3z"
          />
          <path
            d="M4253 3263 c-7 -3 -13 -11 -13 -19 0 -8 -6 -11 -16 -7 -14 5 -15 3
-5 -15 16 -29 39 -28 47 3 7 30 3 43 -13 38z"
          />
          <path
            d="M5100 3247 c-44 -11 -65 -34 -81 -85 -20 -69 7 -119 79 -143 68 -23
153 46 152 124 -1 67 -80 122 -150 104z m75 -27 c3 -5 1 -10 -4 -10 -6 0 -11
5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"
          />
          <path
            d="M4387 3226 c-8 -9 -20 -16 -26 -16 -6 0 -11 -7 -11 -16 0 -13 6 -15
28 -9 36 9 46 20 35 40 -9 15 -11 15 -26 1z"
          />
          <path
            d="M3839 3120 c-27 -22 -22 -25 15 -10 14 5 26 14 26 20 0 16 -15 12
-41 -10z"
          />
          <path
            d="M6110 3083 c-96 -67 -108 -158 -32 -237 86 -91 241 -15 241 119 0 94
-135 170 -209 118z"
          />
          <path
            d="M3979 3072 c5 -10 17 -24 25 -32 13 -10 16 -10 16 3 0 29 -12 47 -31
47 -16 0 -18 -4 -10 -18z"
          />
          <path d="M3905 3059 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
          <path
            d="M3840 3006 c0 -8 7 -16 15 -20 9 -3 15 0 15 8 0 8 -7 16 -15 20 -9 3
-15 0 -15 -8z"
          />
          <path
            d="M4290 2966 c0 -22 17 -28 41 -16 24 14 21 19 -15 26 -18 3 -26 0 -26
-10z"
          />
          <path
            d="M3930 2960 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
          />
          <path
            d="M4376 2941 c-9 -17 -22 -31 -29 -32 -18 -1 -40 -6 -59 -14 -10 -4
-24 1 -38 15 l-22 22 -21 -21 c-17 -16 -21 -33 -22 -83 0 -60 1 -61 29 -65 27
-4 28 -2 21 24 -3 15 -11 30 -16 31 -14 6 -10 32 6 38 8 4 15 14 16 23 0 21
17 -19 25 -62 3 -18 7 -39 9 -47 2 -8 4 -21 4 -28 1 -10 11 -12 40 -7 26 4 41
2 47 -6 5 -9 -7 -11 -54 -6 -34 3 -62 2 -62 -1 0 -4 15 -17 33 -29 60 -41 107
-27 166 47 35 44 40 57 36 87 -2 20 -9 41 -15 47 -5 5 -10 18 -10 28 0 10 -12
25 -27 35 -16 10 -31 22 -34 27 -4 5 -14 -5 -23 -23z m62 -56 c2 -11 11 -26
20 -33 12 -9 13 -15 4 -24 -7 -7 -12 -22 -12 -34 0 -31 -26 -37 -82 -18 -38
12 -48 20 -48 38 0 32 24 61 44 53 26 -10 16 -50 -14 -57 -22 -5 -23 -7 -7
-13 31 -12 64 -8 78 9 11 13 10 21 -6 50 -10 19 -23 34 -29 34 -6 0 -4 5 4 10
22 15 44 8 48 -15z"
          />
          <path d="M4006 2941 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
          <path
            d="M5655 2939 c-53 -31 -75 -72 -75 -137 0 -43 5 -56 42 -102 l42 -52
66 4 c80 6 104 20 130 75 25 54 25 89 0 131 -42 69 -63 83 -126 88 -36 3 -67
0 -79 -7z"
          />
          <path
            d="M4716 2865 c-42 -28 -75 -94 -73 -146 1 -33 9 -50 42 -85 44 -46 45
-47 124 -45 48 1 57 5 92 40 37 37 39 42 39 100 0 61 -20 121 -40 121 -21 0
-25 2 -42 16 -26 25 -105 24 -142 -1z"
          />
          <path
            d="M5215 2873 c-11 -3 -37 -19 -58 -36 -33 -26 -38 -34 -33 -59 3 -15 1
-28 -4 -28 -6 0 -10 -17 -10 -38 0 -30 8 -46 39 -80 67 -72 141 -83 204 -31
20 17 37 35 37 41 0 6 7 20 16 30 15 15 15 25 6 74 -16 76 -41 109 -96 123
-46 11 -67 12 -101 4z"
          />
          <path
            d="M3905 2860 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
